<template>
  <div class="h-full">
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
      @onPageChange="onPageChange" :queryParam="queryParam">
      <div slot="buttonGroup" class="h-handle-button">
        <div class="h-b">
          <el-button type="primary" size="small" @click="add" v-right-code="'District:Edit'">新增</el-button>
        </div>
        <div class="h-b">
          <el-button type="primary" size="small" @click="enable" v-right-code="'District:Edit'">启用</el-button>
        </div>
        <div class="h-b">
          <el-button type="primary" size="small" @click="disable" v-right-code="'District:Edit'">停用</el-button>
        </div>
        <div class="h-b">
          <el-button type="primary" size="small" @click="del" v-right-code="'District:Batchdelate'">删除</el-button>
        </div>
      </div>

      <p slot="elList">
        <el-table ref="sysConfigListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
          highlight-current-row>
          <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
          <el-table-column v-for="(col, index, count) in dataSource.ColDefs.BodyFieldParams" :key="index"
            :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
            :fixed="index == 0 && (!config || !config.isDetailDisplay)" :min-width="!config || !config.isDetailDisplay ? parseInt(col.Width) : 0
      " :show-overflow-tooltip="col.FieldName == 'ConfigMemo'" v-if="col.Visible &&
      (!config ||
        !config.isDetailDisplay ||
        (config.isDetailDisplay && index < 2))
      ">
            <template slot-scope="scope">
              <span v-if="col.FieldName === 'DisCode'"><span type="text" @click="rowClick(scope.row)" style="
                    text-decoration: underline;
                    color: #1874cd;
                    cursor: pointer;
                  ">{{ scope.row[col.FieldName] }}</span></span>
              <span v-else> {{ scope.row[col.FieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </p>
    </fixed-list>
  </div>
</template>
<script>
export default {
  mounted() {
    this.Event.$on("reloadPageList", () => this.reloadPageList());
    this.Event.$on("onAdd", () => this.add());
    this.initialize();
  },
  data() {
    return {
      multipleSelection: [],
      queryParam: {
        PageIndex: 1,
        PageSize: 10,
      },
      dataSource: {
        ColDefs: {},
        Result: [],
        TotalCount: 0,
      },
    };
  },
  props: {
    config: {
      isDetailDisplay: false,
      isButtonlDisplay: false,
    },
    option: {},
  },
  methods: {
    reloadPageList: function () {
      this.initialize();
    },
    initialize() {
      this.onPageChange(this.queryParam);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("onCheckRow", this.multipleSelection);
    },
    onPageChange(param) {
      var _this = this;
      this.queryParam = param;
      this.$ajax.query("omsapi/district/searchlist", "post", param, (data) => {
        _this.dataSource = data;
      });
    },
    onDataSourceChange(ds) {
      var _this = this;

      _this.dataSource = {
        ColDefs: {
          BodyFieldParams: [],
        },
        Result: [],
        TotalCount: 0,
      };
      _this.$nextTick(function () {
        _this.dataSource = ds;
      });
    },
    rowClick(row) {
      this.$ajax.send(
        "omsapi/district/get",
        "get",
        {
          id: row.Id,
        },
        (data) => {
          if (data.Result.RegionType != null) {
            data.Result.RegionType = data.Result.RegionType + "";
          }
          this.Event.$emit("clearEditSysConfigForm");
          this.onChangeEditDataSource(data.Result);
        }
      );
    },
    add() {
      this.$ajax.send("omsapi/district/getempty", "get", {}, (data) => {
        this.onChangeEditDataSource(data.Result);
      });
    },
    enable() {
      if (
        this.multipleSelection == null ||
        this.multipleSelection.length == 0
      ) {
        this.Utils.messageBox(`未选数据.`, "error");
        return false;
      }

      var filterRows = this.multipleSelection.filter((p) => p.Status === 100);
      if (filterRows.length > 0) {
        this.Utils.messageBox("只能选择未启用状态的数据.", "error");
        return false;
      }
      var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
      var _this = this;
      this.$ajax.send("omsapi/district/enable", "post", ids, (data) => {
        if (data.IsSuccess) {
          _this.Utils.messageBox("保存成功.", "success");
          _this.reloadPageList();
        } else {
          _this.Utils.messageBox(data.OperationDesc, "error");
        }
      });
    },
    disable() {
      if (
        this.multipleSelection == null ||
        this.multipleSelection.length == 0
      ) {
        this.Utils.messageBox(`未选数据.`, "error");
        return false;
      }

      var filterRows = this.multipleSelection.filter((p) => p.Status === 200);
      if (filterRows.length > 0) {
        this.Utils.messageBox("只能选择已启用状态的数据.", "error");
        return false;
      }
      var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
      var _this = this;
      this.$ajax.send("omsapi/district/disable", "post", ids, (data) => {
        if (data.IsSuccess) {
          _this.Utils.messageBox("保存成功.", "success");
          _this.reloadPageList();
        } else {
          _this.Utils.messageBox(data.OperationDesc, "error");
        }
      });
    },
    del() {
      if (
        this.multipleSelection == null ||
        this.multipleSelection.length == 0
      ) {
        this.Utils.messageBox(`未选择要删除的数据项.`, "error");
        return false;
      }
      var This = this;
      this.Utils.confirm(
        {
          title: "是否确认删除数据项?",
          content: "删除后数据不可恢复,该数据项将不可使用,是否确认删除?",
        },
        () => {
          var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");

          this.$ajax.send(
            "omsapi/district/batchdelate",
            "post",
            ids,
            (data) => {
              This.onPageChange(This.queryParam);
              This.Utils.messageBox("数据项删除成功.", "success");
            }
          );
        },
        () => {
          This.Utils.messageBox("已取消删除.", "info");
        }
      );
    },
    onChangeEditDataSource(data) {
      this.config.isDetailDisplay = true;
      this.$emit("onChangeEditDataSource", data);
    },
  },
  //components: {
  //}
};
</script>
<style></style>